import { useContext, useMemo } from "react"
import { UserContext } from "~/context/UserContext"
import { UserFeatureFlagsType } from "~/types/userTypes"

export function useUserFeatureFlags(): UserFeatureFlagsType {
  const userContext = useContext(UserContext)
  const user = userContext?.user

  const featureFlags = useMemo(
    () => user?.userFeatureFlags || {},
    [user?.userFeatureFlags]
  )  

  return featureFlags
}
