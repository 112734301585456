import React from "react"
import { doc, setDoc } from "@firebase/firestore"
import { FirebaseError } from "@firebase/util"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation } from "@tanstack/react-query"
import { httpsCallable } from "firebase/functions"
import { Circle, CircleCheckBig } from "lucide-react"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { useAnalytics } from "use-analytics"
import { BaseControl } from "~/components/base/base-control"
import { InlineCheckbox } from "~/components/base/inline-checkbox"
import BoxContainer from "~/components/BoxContainer"
import CountrySelector from "~/components/CountrySelector"
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  SubmitButton,
  toast,
} from "~/components/ui"
import {
  getUserFriendlyAuthError,
  sendVerificationEmail,
  signup,
} from "~/lib/auth"
import type { MutationOptions } from "~/lib/react-query"
import { z, zodErrorMap } from "~/lib/zod"
import { db, functions } from "~/services/firebase"
import { supportedCountries } from "~/utils/countries"
import { supportedDictationLanguages } from "~/utils/languages"

type MutationError = FirebaseError | Error

type MutationVariables = {
  email: string
  password: string
  country: { code: string }
  tac: boolean
  referralId?: string
}

const signUpFormSchema = z.object({
  email: z
    .string()
    .min(1, { message: "This field is required" })
    .email({ message: "Invalid email address" }),
  password: z
    .string()
    .min(1, { message: "This field is required" })
    .min(8, { message: "Password must be at least 8 characters" }),
  country: z.object({
    code: z.string().min(1, { message: "Please select a country" }),
  }),
  tac: z
    .boolean()
    .refine((val) => val, { message: "Please agree to Joy Terms of Service" }),
})

const detectLanguageCode = (): string => {
  if (typeof window !== "undefined" && window.navigator?.language) {
    return window.navigator.language.split("-")[0].toLocaleLowerCase()
  }
  return ""
}

function useRegisterUser({
  ...options
}: MutationOptions<{
  email: string
  password: string
  country: { code: string }
  tac: boolean
  referralId?: string
}>) {
  const { track } = useAnalytics()

  const language = React.useMemo(() => {
    const navigatorLanguageCode = detectLanguageCode()

    return supportedDictationLanguages.some(
      (lang) => lang.code === navigatorLanguageCode
    )
      ? navigatorLanguageCode
      : supportedDictationLanguages[0].code
  }, [])

  const handleFirebaseError = (error: FirebaseError) => {
    const message = getUserFriendlyAuthError(error, error.message)
    toast.error(message)
  }

  return useMutation<void, MutationError, MutationVariables>({
    ...options,
    mutationFn: async ({ email, password, country, tac, referralId }) => {
      if (!tac) {
        throw new Error("You must accept the terms and conditions.")
      }

      // Sign up user
      const signupData = await signup(email, password)

      // Send verification email
      await sendVerificationEmail(signupData.user)

      // Set user country to user profile
      if (country?.code && language) {
        // Update user document with country code and language
        const dataRef = doc(db, `users/${signupData.user.uid}`)

        await setDoc(
          dataRef,
          {
            userProfile: {
              regCountryCode: country.code.toUpperCase(),
              language: language,
              dictationLanguage: language,
            },
          },
          {
            merge: true,
          }
        )
      }

      // For non SE users, add them to the free trial plan
      if (country.code.toUpperCase() !== "SE") {
        // Get the function reference
        const startNotesTrialForUser = httpsCallable(
          functions,
          "startNotesTrialForUser"
        )

        // Start the notes trial
        await startNotesTrialForUser()
      }

      if (referralId) {
        const registerReferral = httpsCallable(functions, "registerReferral")

        // Call the referral function
        await registerReferral({ referralId })
      }

      await track("sign_up") // Keep as sign_up for GA to treat as conversion
    },
    onError: (error) => {
      if (error instanceof FirebaseError) {
        handleFirebaseError(error)
      } else {
        toast.error(error?.message ?? "An unknown error occurred")
      }
    },
  })
}

export default function SignUp() {
  const navigate = useNavigate()
  const [supportedCountry, setSupportedCountry] = React.useState(true)

  // Get the referral ID from the referrer URL
  // Replace try catch below with URL.canParse later.
  let referralId: string | undefined
  if (document.referrer) {
    try {
      const referrerUrl = new URL(document.referrer)
      referralId = referrerUrl?.searchParams.get("joy_ref_id") || undefined
    } catch {
      // Do nothing
    }
  }

  const form = useForm<MutationVariables>({
    resolver: zodResolver(signUpFormSchema, { errorMap: zodErrorMap }),
    defaultValues: {
      email: "",
      password: "",
      country: { code: "" },
      tac: false,
    },
  })

  const registerUser = useRegisterUser({
    onSuccess: () => {
      navigate("/verify-email")
    },
  })

  return (
    <BoxContainer>
      <div className="flex flex-col items-center gap-1">
        <h2 className="w-full py-0.5 text-2xl font-bold text-center">
          Register and start taking notes
        </h2>
        <p className="text-center">No credit card needed.</p>
      </div>

      <Form
        form={form}
        className="space-y-6"
        onSubmit={form.handleSubmit(async (values) => {
          await registerUser.mutateAsync({ ...values, referralId })
        })}
      >
        <BaseControl
          name="email"
          label="Email"
          render={({ field }) => (
            <Input
              {...field}
              type="email"
              placeholder={"Enter your email"}
            />
          )}
        />

        <FormField
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  type="password"
                  placeholder={"•••••••"}
                />
              </FormControl>
              <FormDescription className="flex items-center gap-1.5">
                {typeof field.value === "string" && field.value.length >= 8 ? (
                  <CircleCheckBig
                    data-testid="checked-circle"
                    className="size-4 text-situational-success"
                  />
                ) : (
                  <Circle
                    data-testid="circle"
                    className="size-4"
                  />
                )}
                <span>Minimum 8 characters</span>
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="country.code"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Country of practice</FormLabel>
              <FormControl>
                <CountrySelector
                  {...field}
                  onChange={(value) => {
                    field.onChange(value)
                    setSupportedCountry(
                      supportedCountries().some((c) => c.code === value)
                    )
                  }}
                />
              </FormControl>
              <FormDescription>
                {!supportedCountry && (
                  <span className="text-[#d36c65]">
                    Please note that your data will be processed within the EU
                    in accordance with GDPR and&nbsp;
                    <Link
                      className="underline underline-always"
                      to="https://joy.day/service-agreement"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Joy Terms and Conditions
                    </Link>
                    .
                  </span>
                )}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="tac"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <InlineCheckbox
                  {...field}
                  id="tac"
                  checked={field.value === true}
                  defaultChecked={false}
                  onCheckedChange={field.onChange}
                  label={
                    <span>
                      I agree with{" "}
                      <Link
                        className="underline"
                        to="https://joy.day/service-agreement"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Joy Terms and Conditions
                      </Link>
                    </span>
                  }
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <SubmitButton
          type="submit"
          className="w-full"
          isSubmitting={form.formState.isSubmitting}
        >
          Sign up
        </SubmitButton>
      </Form>

      <p className="text-sm text-center text-[#d36c65]">
        Having trouble? Drop us a line at support@joy.day
      </p>

      <hr className="w-4/5 mx-auto mt-4" />

      <Button
        asChild
        size="sm"
        variant="link"
        className="text-foreground underline hover:text-primary"
      >
        <Link to="/login">Already have an account?</Link>
      </Button>
    </BoxContainer>
  )
}
