import React from "react"
import { useAnalytics } from "use-analytics"
import { Button, Progress } from "~/components/ui"
import {
  Credenza,
  CredenzaBody,
  CredenzaContent,
  CredenzaFooter,
  CredenzaHeader,
  CredenzaTitle,
} from "~/components/ui/credenza"
import { useAuth } from "~/context/AuthContext"
import { storage } from "~/services/firebase"
import { LocalRecordings, RemoteRecording } from "~/utils/recordings"
import { Note } from "./types"

export enum NoteRetryUploadState {
  INIT = "init",
  UPLOADING = "uploading",
  SUCCESS = "success",
  FAIL = "fail",
}

interface NoteRetryUploadDialogProps {
  note: Note | undefined
  onDismissed: (status: NoteRetryUploadState) => void
}

const NoteRetryUploadDialog: React.FC<NoteRetryUploadDialogProps> = ({
  note,
  onDismissed,
}) => {
  const { currentUser } = useAuth()
  const { track } = useAnalytics()
  const [uploadingDismissed, setUploadingDismissed] = React.useState(false)
  const [uploadingProgress, setUploadingProgress] = React.useState(0)
  const [uploadState, setUploadState] = React.useState<NoteRetryUploadState>(
    NoteRetryUploadState.INIT
  )

  const forceRetry = async (note: Note | undefined) => {
    if (note === undefined || currentUser?.uid === undefined) {
      return
    }
    // Reset the state
    setUploadingDismissed(false)
    setUploadState(NoteRetryUploadState.UPLOADING)

    void track("Note User_force_retry_uploading_from_processing_dialog")
    try {
      // Get the audio blob from local storage
      const local = new LocalRecordings()
      const blob = await local.get(note.id)
      if (blob === undefined) {
        console.warn(`Failed to sync to remote storage, missing local data`)
        throw new Error("No local recording found for the note")
      }

      // Create a new remote recording
      const remote = new RemoteRecording(
        storage,
        currentUser.uid,
        note.id,
        blob.type,
        (newStatus) => {
          if (newStatus.totalBytes) {
            setUploadingProgress(
              Math.round((newStatus.bytesWritten / newStatus.totalBytes) * 100)
            )
          }
          if (newStatus.state === "error") {
            throw new Error(newStatus.errorMessage || "Unknown error")
          }
          if (newStatus.state === "synced") {
            setUploadState(NoteRetryUploadState.SUCCESS)
            void track(
              "Note Success_force_retry_uploading_from_processing_dialog"
            )
          }
        }
      )

      // Write and flush to the remote store
      await remote.write(blob).catch((error) => {
        throw new Error(error)
      })

      await remote.flush()
    } catch (error) {
      console.log(error)
      setUploadState(NoteRetryUploadState.FAIL)
      void track("Note Error_force_retry_uploading_from_processing_dialog")
    }
  }

  React.useEffect(() => {
    if (note?.id) {
      void forceRetry(note)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note])

  return (
    <Credenza
      open={!uploadingDismissed}
      onOpenChange={() => {
        setUploadingDismissed(true)
        onDismissed(uploadState)
      }}
    >
      <CredenzaContent className={""}>
        <CredenzaHeader className="space-y-0.5">
          <CredenzaTitle>Retrying upload</CredenzaTitle>
        </CredenzaHeader>
        <CredenzaBody
          data-vaul-no-drag=""
          className="overflow-y-auto max-h-96 md:max-h-[27rem] px-4"
        >
          <div className="flex flex-col justify-center">
            {uploadState === NoteRetryUploadState.SUCCESS && (
              <p className="mb-2">
                Upload successful. Your note is now being typed up.
              </p>
            )}

            {uploadState === NoteRetryUploadState.FAIL && (
              <p className="mb-2">
                Upload failed. Please try again or talk to support.
              </p>
            )}

            {(uploadState === NoteRetryUploadState.UPLOADING ||
              uploadState === NoteRetryUploadState.INIT) && (
              <div className="flex flex-col justify-center">
                <p className="mb-2">
                  Upload in progress.
                  <br />
                  You can close this dialog, upload will continue in the
                  background.
                </p>
                {
                  <div className="flex flex-row gap-1 items-center justify-between">
                    <Progress value={uploadingProgress} />
                    <p className="text-sm font-medium text-gray-500">
                      {uploadingProgress}%
                    </p>
                  </div>
                }
              </div>
            )}
          </div>
        </CredenzaBody>
        <CredenzaFooter className="">
          <Button
            variant="ghost"
            onClick={(e) => {
              e.preventDefault()
              onDismissed(uploadState)
            }}
            className=""
          >
            Close
          </Button>
        </CredenzaFooter>
      </CredenzaContent>
    </Credenza>
  )
}

export default NoteRetryUploadDialog
