import React from "react"
import {
  CircleUserRound,
  MegaphoneIcon,
  MessagesSquare,
  StickyNote,
} from "lucide-react"
import { useNavigate } from "react-router-dom"
import { useAnalytics } from "use-analytics"
import { ReferModal } from "~/components/modals/ReferModal"
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerTitle,
  Separator,
} from "~/components/ui"
import { useStore } from "~/hooks/useStore"
import JoyCommunityModal from "./JoyCommunityModal"

const shouldShowReferModal = () => {
  const params = new URLSearchParams(window.location.search)
  return params.has("__referral") && params.get("__referral") === "1"
}

export default function MainDrawer() {
  const navigate = useNavigate()
  const { track } = useAnalytics()

  const [showCommunityRedirect, setShowCommunityRedirect] =
    React.useState(false)
  const [showReferModal, setShowReferModal] = React.useState(() =>
    shouldShowReferModal()
  )

  const [navIsOpened, setNavIsOpened] = useStore((state) => [
    state.navIsOpened,
    state.setNavIsOpened,
  ])

  return (
    <>
      <JoyCommunityModal
        open={showCommunityRedirect}
        onClose={() => setShowCommunityRedirect(false)}
      />

      <ReferModal
        isOpen={showReferModal}
        onOpenChange={setShowReferModal}
      />

      <Drawer
        direction={"left"}
        open={navIsOpened}
        onOpenChange={setNavIsOpened}
      >
        <DrawerContent
          indicator={false}
          className="border-0 rounded-2xl top-0 bottom-0 left-0 my-4 ml-3 w-64 md:w-96 after:!content-none"
          aria-describedby=""
        >
          <DrawerTitle className="sr-only">Navigation</DrawerTitle>

          <ul className="p-4 flex flex-col space-y-2">
            <Button
              variant="ghost"
              className="flex w-full justify-start"
              onClick={() => {
                navigate("/notes")
                setNavIsOpened(false)
              }}
            >
              <StickyNote className="size-5" />
              <span>Notes</span>
            </Button>

            <Separator />

            <Button
              variant="ghost"
              className="flex w-full justify-start"
              onClick={() => {
                navigate("/account")
                setNavIsOpened(false)
              }}
            >
              <CircleUserRound className="size-5" />
              <span>My account</span>
            </Button>

            <Button
              variant="ghost"
              className="flex w-full justify-start"
              onClick={() => {
                setNavIsOpened(false)
                setShowReferModal(true)
                void track("Referral Open_modal")
              }}
            >
              <MegaphoneIcon className="size-5" />
              <span>Refer a friend</span>
            </Button>

            <Button
              variant="ghost"
              className="flex w-full justify-start"
              onClick={() => {
                setShowCommunityRedirect(true)
                setNavIsOpened(false)
              }}
            >
              <MessagesSquare
                fill="currentColor"
                className="size-5"
              />
              <span>Joy Community</span>
            </Button>
          </ul>
        </DrawerContent>
      </Drawer>
    </>
  )
}
