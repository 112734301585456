import { useMemo } from "react"
import useFetchDocuments from "./useFetchDocuments"

interface PromptFragmentData {
  notePrefix?: string
  noteSuffix?: string
  noteDefault?: string

  [key: string]: string | undefined
}

export interface UsePromptFragmentsReturn {
  notePromptPrefix?: string
  notePromptSuffix?: string
  notePromptDefault?: string
  isPending: boolean
}

export function usePromptFragments(language: string): UsePromptFragmentsReturn {
  const [fragments, isPending] = useFetchDocuments("promptFragments")

  return useMemo(() => {
    if (isPending || !fragments) {
      return { isPending }
    }

    const fragment = fragments.find((f) => f.id === language)

    if (!fragment || !fragment.data) {
      return { isPending }
    }

    const { notePrefix, noteSuffix, noteDefault } =
      fragment.data as PromptFragmentData

    return {
      notePromptPrefix: notePrefix,
      notePromptSuffix: noteSuffix,
      notePromptDefault: noteDefault,
      isPending,
    }
  }, [fragments, isPending, language])
}
