import { useCallback, useContext, useMemo, useState } from "react"
import { deleteField, doc, serverTimestamp, setDoc } from "firebase/firestore"
import { useAuth } from "~/context/AuthContext"
import { UserContext } from "~/context/UserContext"
import { db } from "~/services/firebase"
import { UserJourneyType } from "~/types/userTypes"

const isEqualPartial = (
  partial: Partial<UserJourneyType>,
  full: UserJourneyType | undefined
): boolean => {
  if (!full) return false

  return Object.keys(partial).every((key) => {
    const k = key as keyof UserJourneyType
    return JSON.stringify(partial[k]) === JSON.stringify(full[k])
  })
}

type UseUserJourneyReturn = [
  UserJourneyType | null,
  (updates: Partial<UserJourneyType>) => Promise<void>,
  boolean,
]

export function useUserJourney(): UseUserJourneyReturn {
  const userContext = useContext(UserContext)
  const user = userContext?.user
  const { currentUser } = useAuth()

  const [isPending, setIsPending] = useState(false)

  const updateUserJourney = useCallback(
    async (updates: Partial<UserJourneyType>) => {
      if (!currentUser?.uid) {
        console.warn("Current user is null. Skipping updateUserJourney.")
        return
      }
      if (!user?.userJourney) {
        console.warn("User is null. Skipping updateUserJourney.")
        return
      }
      if (!isEqualPartial(updates, user.userJourney)) {
        setIsPending(true)
        try {
          const updatedJourney = { ...user.userJourney, ...updates }

          // When the state changes, update the stateChangedAt timestamp
          // and remove flag if it exists
          if (
            user.userJourney.state !== updates.state &&
            updates.state !== undefined
          ) {
            updatedJourney.stateChangedAt = serverTimestamp()
            updatedJourney.flag = deleteField()
          }

          const userRef = doc(db, "users", currentUser.uid)
          await setDoc(
            userRef,
            { userJourney: updatedJourney },
            { merge: true }
          )
        } catch (error) {
          console.error("Failed to update user journey:", error)
        } finally {
          setIsPending(false)
        }
      }
    },
    [currentUser?.uid, user?.userJourney]
  )

  return useMemo(
    () => [user?.userJourney || null, updateUserJourney, isPending],
    [user?.userJourney, updateUserJourney, isPending]
  )
}
