import React from "react"
import CheckmarkIcon from "~/assets/icons/checkmark.svg?react"
import soapIcon from "~/assets/icons/soap-icon.png"
import StarsIcon from "~/assets/icons/stars-icon.svg?react"
import { Button } from "~/components/ui"
import { useUserFeatureFlags } from "~/hooks/useUserFeatureFlags"
import { PROMPTS } from "~/utils/prompts"
import MyOwnTransformPrompt from "./MyOwnTransformPrompt"
import { Note, Transform, TransformType } from "./types"

interface NoteTransformContentProps {
  selectedButton: TransformType
  handleTransformClick: (transformType: TransformType) => void
  transforms: Transform[]
  note: Note | undefined
}

interface Feature {
  text: string
}

const features: Feature[] = [
  { text: "Silences" },
  { text: "Umms & uhhs" },
  { text: "Punctuation" },
]

interface FeatureItemProps {
  text: string
  Icon?: React.ReactNode
  className?: string
}

const FeatureItem: React.FC<FeatureItemProps> = ({ text, Icon, className }) => (
  <div
    className={`flex w-full justify-center items-center md:mb-1 min-w-[320px] gap-1 ${className}`}
  >
    <p className="text-sm">{text}</p>
    {Icon}
  </div>
)

const FeatureList = ({
  IconComponent,
}: {
  IconComponent: React.FunctionComponent
}) => (
  <div className="">
    <h3 className="text-center text-lg font-bold">Joy fixes:</h3>

    {features.map((feature, index) => (
      <FeatureItem
        key={index}
        text={feature.text}
        Icon={<IconComponent />}
      />
    ))}
  </div>
)

interface TransformHeaderProps {
  icon?: React.ReactNode
  rightIcon?: React.ReactNode
  title: React.ReactNode
}

const TransformHeader: React.FC<TransformHeaderProps> = ({
  icon,
  rightIcon,
  title,
}) => (
  <div className="w-full mt-4 mb-4 gap-1">
    <div className="flex flex-row items-center justify-center">
      {icon &&
        (typeof icon === "string" ? (
          <img
            src={icon}
            alt="Icon"
            className="w-8 h-8 mr-2"
          />
        ) : (
          icon
        ))}
      <div className="w-fit">
        <h2 className="text-xl font-bold w-fit">{title}</h2>
      </div>
      {rightIcon &&
        (typeof rightIcon === "string" ? (
          <img
            src={rightIcon}
            alt="Right Icon"
            className="w-8 h-8 ml-2"
          />
        ) : (
          rightIcon
        ))}
    </div>
  </div>
)

interface ActionButtonProps {
  onClick: () => void
  label: string
  disabled?: boolean
}

const ActionButton: React.FC<ActionButtonProps> = ({
  onClick,
  label,
  disabled = false,
}) => (
  <Button
    className="rounded-lg sm:w-96 w-full font-archivo text-xl mb-2 px-8 sm:px-0 py-3"
    onClick={onClick}
    disabled={disabled}
  >
    {disabled ? (
      <span>
        <strong>{label}</strong> not available
      </span>
    ) : (
      <span>
        Use <strong>{label}</strong>
      </span>
    )}
  </Button>
)

export default function NoteTransformContent({
  selectedButton,
  handleTransformClick,
  transforms,
  note,
}: NoteTransformContentProps) {
  const useMyOwnTransformFeature = useUserFeatureFlags()["useMyOwnTransform"]

  // Disable template buttons that don't exist in the transforms array, or where the transform status is not "ok"
  const isDisabled = (transformType: TransformType) => {
    // If the transform type is not in the transforms array, disable the button
    return !transforms.some(
      (t) => t.promptId === transformType.valueOf() && t.status === "ok"
    )
  }

  const renderContent = () => {
    switch (selectedButton) {
      case TransformType.Default:
        return (
          <div className="flex-1 flex flex-col justify-between items-center max-w-[320px] mx-auto px-4">
            <div>
              <TransformHeader
                rightIcon={<StarsIcon />}
                title="Your words, touched up."
              />
              <FeatureList IconComponent={CheckmarkIcon} />
            </div>
            <div className="flex flex-col items-center">
              <p className="text-sm mb-1">{PROMPTS.MISTAKES_INFO}</p>
              <ActionButton
                onClick={() => handleTransformClick(TransformType.Default)}
                label="Proofread"
                disabled={isDisabled(TransformType.Default)}
              />
            </div>
          </div>
        )
      case TransformType.SOAP:
        return (
          <div className="flex-1 flex flex-col justify-between items-center max-w-[320px] mx-auto px-4">
            <div className="flex-flex-col items-center">
              <TransformHeader
                icon={soapIcon}
                title={
                  <div className="w-[260px]">
                    <span className="font-bold"></span>S
                    <span className="font-medium">ubjective, </span>
                    <span className="font-bold">O</span>
                    <span className="font-medium">bjective, </span>
                    <span className="font-bold">A</span>
                    <span className="font-medium">ssessment, </span>
                    <span className="font-bold">P</span>
                    <span className="font-medium">lan format.</span>
                  </div>
                }
              />
              <FeatureList IconComponent={CheckmarkIcon} />
            </div>
            <div className="flex flex-col items-center">
              <p className="text-sm mb-1">{PROMPTS.MISTAKES_INFO}</p>
              <ActionButton
                onClick={() => handleTransformClick(TransformType.SOAP)}
                label="SOAP"
                disabled={isDisabled(TransformType.SOAP)}
              />
            </div>
          </div>
        )
      case TransformType.MyOwn: {
        return useMyOwnTransformFeature ? (
          <div className="flex-1 flex flex-col justify-between items-center xs:max-w-[320px] sm:max-w-[600px] mx-auto px-4">
            <MyOwnTransformPrompt
              handleSubmit={() => {
                handleTransformClick(TransformType.MyOwn)
              }}
              note={note}
              transformType={TransformType.MyOwn}
            />
          </div>
        ) : (
          <div className="flex flex-col justify-between items-center h-full max-w-[320px] mx-auto px-4">
            <TransformHeader title="Want a custom format with your own headings?" />
            <p className="text-lg text-center mt-8 mb-4">Let&apos;s do it!</p>
            <p className="text-lg text-center mb-4">
              Reach out to support@joy.day, or ping us in the in-app chat.
            </p>
            <Button className="rounded-lg sm:w-96 w-full h-12 font-archivo text-xl mb-4 mt-auto px-8 sm:px-0">
              <a
                href="mailto:support@joy.day"
                className="py-3 px-5"
              >
                Email support@joy.day
              </a>
            </Button>
          </div>
        )
      }
    }
  }

  return <div className="flex flex-col flex-1">{renderContent()}</div>
}
