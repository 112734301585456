import { FirebaseError } from "@firebase/util"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  QuerySnapshot,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore"
import { Observable } from "rxjs"
import { useAnalytics } from "use-analytics"
import { useAuth } from "~/context/AuthContext"
import type { MutationOptions, QueryParameter } from "~/lib/react-query"
import { Note } from "~/pages/Notes/types"
import { db } from "~/services/firebase"
import { getNoteDateTitle } from "~/utils/helperFunctions"
import { useUserStatistics } from "../useUserStatistics"
import { useSubscription } from "./useSubscription"
import { fromRef } from "./utils"

export type Client = {
  name: string
  properties: {
    type: string
    value: string | { countryCode: number; number: number }
  }[]
  associatedNoteIds: string[]
  id: string
}

/* Queries */
export function useClients() {
  const { currentUser } = useAuth()

  return useSubscription<QuerySnapshot, FirebaseError, Client[]>({
    subscriptionKey: ["CLIENTS"],
    subscriptionFn: () => {
      return fromRef(
        collection(db, `users/${currentUser?.uid}/clients`)
      ) as Observable<QuerySnapshot>
    },
    options: {
      select: (snapshot) => {
        return snapshot?.docs?.map((doc) => {
          const { name, properties, associatedNoteIds } = doc.data()

          return {
            id: doc.id,
            name,
            properties,
            associatedNoteIds,
          }
        })
      },
    },
  })
}

export function useClientById({
  clientId,
  reactQuery,
}: QueryParameter<unknown> & {
  clientId: string
}) {
  const { currentUser } = useAuth()

  return useQuery({
    ...reactQuery,
    queryKey: ["CLIENTS", clientId],
    queryFn: async () => {
      const clientRef = doc(db, `users/${currentUser?.uid}/clients/${clientId}`)
      const clientSnapshot = await getDoc(clientRef)

      if (!clientSnapshot.exists()) {
        throw new Error("CLIENT_NOT_FOUND")
      }

      return {
        id: clientSnapshot.id,
        ...clientSnapshot.data(),
      } as Client
    },
  })
}

/* Mutations */
export function useDeleteClient(
  options?: MutationOptions<{
    mode: "delete" | "unlink"
    clientId: string
  }>
) {
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  return useMutation({
    ...options,
    mutationFn: async ({ clientId, mode }) => {
      const noteRef = doc(db, `users/${currentUser?.uid}/clients/${clientId}`)

      // Unlink client from notes
      const notesRef = collection(db, `users/${currentUser?.uid}/notes`)
      const noteSnapshots = await getDocs(
        query(notesRef, where("clientId", "==", clientId))
      )

      const notesToUpdate = noteSnapshots.docs.filter(
        (doc) => doc.data().clientId === clientId
      )

      const actions =
        mode === "unlink"
          ? notesToUpdate.map((note) => {
              const title = getNoteDateTitle(null, note.data() as Note)
              return updateDoc(note.ref, {
                title,
                clientId: null,
              })
            })
          : notesToUpdate.map((note) => {
              return updateDoc(note.ref, {
                deletedAt: serverTimestamp(),
                clientId: null,
                viewedWithUndo: false,
              })
            })

      await Promise.all([deleteDoc(noteRef), ...actions])
    },
    onSettled: (_, error, ...props) => {
      if (!error) {
        void track("Client Deleted")
        void queryClient.invalidateQueries({ queryKey: ["NOTE"] })
        void queryClient.invalidateQueries({ queryKey: ["NOTES"] })
        void queryClient.invalidateQueries({ queryKey: ["CLIENTS"] })
      }

      options?.onSettled?.(_, error, ...props)
    },
  })
}

export function useRemoveClientFromNote(
  options?: MutationOptions<{
    note: Note
  }>
) {
  const queryClient = useQueryClient()
  const { track } = useAnalytics()

  const { currentUser } = useAuth()
  const [userStatistics] = useUserStatistics()

  return useMutation({
    ...options,
    mutationFn: async ({ note }) => {
      const noteRef = doc(db, `users/${currentUser?.uid}/notes/${note.id}`)
      const title = getNoteDateTitle(userStatistics, note)

      await updateDoc(noteRef, {
        title,
        clientId: null,
      })
    },
    onSettled: (_, error, ...props) => {
      if (!error) {
        void track("Note Client_removed")
        void queryClient.invalidateQueries({ queryKey: ["NOTE"] })
        void queryClient.invalidateQueries({ queryKey: ["NOTES"] })
        void queryClient.invalidateQueries({ queryKey: ["CLIENTS"] })
      }

      options?.onSettled?.(_, error, ...props)
    },
  })
}
