import { initializeApp } from "firebase/app"
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check"
import { connectAuthEmulator, getAuth } from "firebase/auth"
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore"
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions"
import { connectStorageEmulator, getStorage } from "firebase/storage"

// Set the firebase config from the environment variables
const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_APP_FIREBASE_SENDER_ID,
  appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_APP_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig, "joy-app")
export const functions = getFunctions(app, "europe-west3")
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)

// export firebase callable functions
export const addLogEvent = httpsCallable(functions, "addLogEvent")

// Set the debug token for App Check if it is enabled
if (import.meta.env.VITE_APP_FIREBASE_APP_CHECK_USE_DEBUG_TOKEN) {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN =
    import.meta.env.VITE_APP_FIREBASE_APP_CHECK_DEBUG_TOKEN
}

// Initialize App Check with the reCAPTCHA enterprise key
if (import.meta.env.VITE_APP_FIREBASE_APPCHECK_RECAPTCHA_KEY) {
  initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(
      import.meta.env.VITE_APP_FIREBASE_APPCHECK_RECAPTCHA_KEY
    ),
    isTokenAutoRefreshEnabled: true,
  })
}

if (import.meta.env.VITE_APP_FIREBASE_USE_LOCAL_FUNCTIONS === "true") {
  console.log("Using local functions")
  connectFunctionsEmulator(functions, "localhost", 5001)
}
if (import.meta.env.VITE_APP_FIREBASE_USE_LOCAL_AUTH === "true") {
  console.log("Using local auth")
  connectAuthEmulator(auth, "http://localhost:9099")
}

if (import.meta.env.VITE_APP_FIREBASE_USE_LOCAL_FIRESTORE === "true") {
  console.log("Using local firestore")
  connectFirestoreEmulator(db, "localhost", 8080)
}

if (import.meta.env.VITE_APP_FIREBASE_USE_LOCAL_STORAGE === "true") {
  console.log("Using local firebase storage")
  connectStorageEmulator(storage, "localhost", 9199)
}

export default functions
