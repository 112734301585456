import * as React from "react"
import { useMutation } from "@tanstack/react-query"
import { httpsCallable } from "firebase/functions"
import { isMobile } from "react-device-detect"
import { useAnalytics } from "use-analytics"
import {
  Button,
  Credenza as Dialog,
  CredenzaBody as DialogBody,
  CredenzaContent as DialogContent,
  CredenzaFooter as DialogFooter,
  CredenzaHeader as DialogHeader,
  CredenzaTitle as DialogTitle,
  CredenzaTrigger as DialogTrigger,
  toast,
} from "~/components/ui"
import { CopyButton } from "~/components/ui/copy-button"
import { cn } from "~/components/ui/utils"
import { functions } from "~/services/firebase"

type ReferModalProps = {
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
}

const getOrCreateReferralFn = httpsCallable<void, { referralId: string }>(
  functions,
  "getOrCreateReferral"
)

async function shareReferral() {
  const { referralId } = await getOrCreateReferralFn().then(
    (res) => res.data ?? {}
  )

  if (!referralId) {
    throw new Error("Failed to get referral, please try again later.")
  }

  return {
    title: "Refer a friend to Joy Notes",
    text: "This tool has really made my note taking easier and more fun. Give it a try for free!",
    url: `https://joy.day/notes?utm_source=referral&utm_medium=in_app&utm_campaign=christmas_2024&joy_ref_id=${referralId}`,
    referralId,
  }
}

export function ReferModal({
  isOpen,
  onOpenChange,
  children,
}: React.PropsWithChildren<ReferModalProps>) {
  const { track } = useAnalytics()

  const [shareInfo, setShareInfo] = React.useState<{
    title: string
    text: string
    url: string
    referralId: string
  }>()

  // Use native share if available and on mobile
  const isShareAvailable =
    isMobile && typeof window.navigator?.share !== "undefined"

  const isClicked = !!shareInfo?.url && !isShareAvailable

  const shareMutate = useMutation({
    mutationFn: async () => {
      const share = shareInfo || (await shareReferral())
      setShareInfo(share)

      if (isShareAvailable) {
        window?.navigator
          ?.share?.({
            title: share.title,
            text: share.text,
            url: share.url,
          })
          .catch((e) => console.warn(e))
      }

      return share
    },
    onError: (e) => {
      toast.error(e?.message || "Failed to share referral")
    },
    onSuccess: () => {
      void track("Referral Shared")
    },
  })

  return (
    <>
      <Dialog
        open={isOpen}
        onOpenChange={onOpenChange}
        shouldScaleBackground={false}
      >
        <DialogTrigger asChild>{children}</DialogTrigger>

        <DialogContent
          indicator={false}
          className="px-0 pt-0 border-0 rounded-t-[3rem] md:rounded-t-xl [&_svg]:text-white md:max-w-md"
          aria-describedby=""
        >
          <div className="block md:hidden absolute inset-x-0 mx-auto mt-4 h-2 w-[100px] rounded-full bg-white/80" />

          <DialogHeader className="text-white pt-8 bg-[url(/img/Christmas-greeting-card.jpg)] bg-cover bg-center h-[220px] rounded-t-[3rem] md:rounded-t-xl overflow-hidden">
            <DialogTitle className="sr-only">Refer a friend</DialogTitle>
          </DialogHeader>

          <DialogBody className="text-left py-8 md:py-4 px-8 md:px-12">
            <div className={cn("mb-6 space-y-2.5", isClicked && "hidden")}>
              <p>
                Every <strong>5 referrals</strong> gifts a
                <strong> Christmas dinner</strong> to <br />
                someone in need.
              </p>

              <p>
                Plus, if a referral becomes a regular user, <br />
                you get a <strong>£25 Amazon voucher.</strong>
              </p>

              <p>Let’s make Christmas a little brighter 🎄 💫</p>
            </div>

            <p className={cn("text-center py-6", isClicked && "hidden")}>
              <Button
                size="lg"
                variant="destructive"
                className="h-[4.5rem] rounded-[1.5rem] text-lg bg-[#CC4333]"
                isLoading={shareMutate.isPending}
                onClick={(e) => {
                  e.preventDefault()
                  shareMutate.mutate()
                }}
              >
                {shareMutate.isPending ? "Creating share link..." : "Share now"}
              </Button>
            </p>

            {isClicked && (
              <>
                <p className="text-muted-foreground text-sm text-center mb-2">
                  Copy link below and send it to your friends
                </p>

                <div className="flex items-center justify-between h-fit gap-2 px-3 py-1.5 text-sm rounded-md max-w-[330px] mx-auto bg-light_gray overflow-hidden">
                  <pre className="bg-transparent text-inherit font-mono font-normal inline-block whitespace-nowrap scrollbar-hide overflow-x-auto">
                    {shareInfo.url}
                  </pre>
                  <CopyButton value={shareInfo.url} />
                </div>
              </>
            )}
          </DialogBody>

          <DialogFooter className="text-center sm:justify-center text-xs text-muted-foreground">
            <p>Refer by 31 Dec 2024. £25 gift card unlocked at 20 notes.</p>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}
