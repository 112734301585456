import React, { useEffect, useState } from "react"
import { doc, getDoc, setDoc } from "firebase/firestore"
import { httpsCallable } from "firebase/functions"
import { useForm } from "react-hook-form"
import { useAnalytics } from "use-analytics"
import {
  BlockUI,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  RichEditor,
  toast,
} from "~/components/ui"
import { useAuth } from "~/context/AuthContext"
import {
  usePromptFragments,
  UsePromptFragmentsReturn,
} from "~/hooks/usePromptFragments"
import { db, functions } from "~/services/firebase"
import { PROMPTS } from "~/utils/prompts"
import { Note, TransformType } from "./types"

type Props = {
  handleSubmit: (prompt: string) => void
  transformType: TransformType
  note: Note | undefined
}

export default function MyOwnTransformPrompt({
  handleSubmit,
  transformType,
  note,
}: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [prompt, setPrompt] = useState<string>("")

  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  // Only support Swedish and English for now, default to English for other languages
  const language = note?.language === "sv" ? note?.language : "en"

  const {
    notePromptPrefix,
    notePromptSuffix,
    notePromptDefault,
    isPending,
  }: UsePromptFragmentsReturn = usePromptFragments(language)

  // Form
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const editorRef = React.useRef<React.ElementRef<typeof RichEditor> | null>(
    null
  )

  const form = useForm({
    values: {
      prompt,
    },
  })

  useEffect(() => {
    if (prompt) {
      editorRef.current?.setMarkdown(prompt)
    }
  }, [prompt])

  // Read the user's prompt fragments
  // Todo: Refactor this to a custom hook
  useEffect(() => {
    setIsLoading(true)
    const fetchUserPromptFragments = async (defaultPrompt: string) => {
      try {
        const docRef = doc(
          db,
          `users/${currentUser?.uid}/promptFragments/${language}`
        )
        const snapshot = await getDoc(docRef)
        if (snapshot.exists()) {
          const data = snapshot.data()
          if (!data || !(data.myOwn as string)) {
            throw new Error("Prompt not found")
          }
          const myOwn: string = data?.myOwn
          setPrompt(myOwn)
        } else {
          setPrompt(defaultPrompt)
        }
      } catch {
        setPrompt(defaultPrompt)
      } finally {
        setIsLoading(false)
      }
    }

    void fetchUserPromptFragments(notePromptDefault || "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notePromptDefault])

  const onSubmit = async (values: { prompt: string }) => {
    setIsSubmitting(true)
    try {
      if (!values.prompt) {
        throw new Error("INVALID_PROMPT")
      }

      const isDirty = !!form.formState.dirtyFields?.prompt

      if (isDirty) {
        void track("Transform User_prompt_updated", { language: language })
        // Save the local prompt
        const userPromptDocRef = doc(
          db,
          `users/${currentUser?.uid}/promptFragments/${language}`
        )
        const userPromptDoc = {
          myOwn: values.prompt,
        }
        await setDoc(userPromptDocRef, userPromptDoc, { merge: true })

        // Save the full prompt
        const docRef = doc(
          db,
          `users/${currentUser?.uid}/prompts/${transformType}`
        )

        const newFullPrompt: string = `${notePromptPrefix || ""} ${values.prompt} ${notePromptSuffix || ""}`

        const newDoc = {
          text: newFullPrompt,
        }
        await setDoc(docRef, newDoc, { merge: true })
      }

      // Run the transform
      //  Get the function reference
      const transformOnDemand = httpsCallable(functions, "transformOnDemand")

      if (note) {
        await transformOnDemand({
          noteId: note.id,
          promptIds: [transformType.valueOf()],
        })
      }

      // Update parent component
      handleSubmit(TransformType.MyOwn.valueOf())
    } catch (error) {
      console.error("Error saving prompt", error)
      toast.error("Error formatting text")
    } finally {
      setIsSubmitting(false)
    }
    return Promise.resolve()
  }

  if (isPending || isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <BlockUI isLoading={isPending || isLoading} />
        <p className="ml-[24px]">Loading prompt ...</p>
      </div>
    )
  }

  if (!note) {
    return (
      <div className="flex justify-center items-center h-full">
        <BlockUI isLoading={isPending} />
        <p className="ml-[24px]">Can not find the note ...</p>
      </div>
    )
  }

  const submitButtonText = isPending ? (
    <span>
      <strong>My own</strong> not available
    </span>
  ) : isSubmitting ? (
    <div className="flex justify-center items-center">
      <BlockUI isLoading />
      <p className="ml-[24px]">Formatting your note..</p>
    </div>
  ) : (
    <span>
      Use <strong>My own</strong>
    </span>
  )

  return (
    <div className="flex-1 flex flex-col justify-between items-left w-full px-4">
      <h2 className="self-center text-xl font-bold">
        Edit the instructions below for your own text format
      </h2>
      <div className="flex-1 overflow-auto my-2">
        <Form
          ref={formRef}
          form={form}
          onSubmit={form.handleSubmit(async (values) => {
            await onSubmit(values)
            form.reset()
          })}
          className="bg-[#F0F0F0] w-full h-full p-4 rounded-lg"
        >
          <FormField
            control={form.control}
            name="prompt"
            rules={{
              required: "A description of your format is required",
            }}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <RichEditor
                    {...field}
                    ref={(ref) => {
                      field.ref(ref)
                      editorRef.current = ref
                    }}
                    placeholder={"Describe your own format here..."}
                    onRequestSave={() => {
                      if (form.formState.isDirty) {
                        formRef.current?.requestSubmit?.()
                      }
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
      </div>

      <div className="flex flex-col items-center">
        <p className="text-sm mb-1">{PROMPTS.MISTAKES_INFO}</p>
        <Button
          className="self-center py-3 px-5 rounded-lg sm:w-96 h-12 w-full font-archivo text-xl mb-2 px-8 sm:px-0"
          onClick={(e) => {
            e.preventDefault()
            formRef.current?.requestSubmit?.()
          }}
          disabled={isPending || isSubmitting}
        >
          {submitButtonText}
        </Button>
      </div>
    </div>
  )
}
