import { useEffect, useState } from "react"
import { collection, getDocs, query } from "firebase/firestore"
import { db } from "~/services/firebase"

interface DocumentData {
  id: string
  data: unknown
}

const useFetchDocuments = (collectionName: string): [DocumentData[] | null, boolean] => {
  const [documents, setDocuments] = useState<DocumentData[] | null>(null)
  const isPending = documents === null

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, collectionName))
      const querySnapshot = await getDocs(q)
      const docsArray: DocumentData[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }))
      setDocuments(docsArray)
    }

    fetchData().catch(console.error)
  }, [collectionName])

  return [documents, isPending]
}

export default useFetchDocuments
