import { create } from "zustand"

export type Store = {
  navIsOpened: boolean
  setNavIsOpened: (value: boolean) => void

  updateAvailable: boolean
  setUpdateAvailable: (value: boolean) => void
}

export const useStore = create<Store>()((set) => ({
  navIsOpened: false,
  setNavIsOpened: (value) => set({ navIsOpened: value }),

  updateAvailable: false,
  setUpdateAvailable: (value) => set({ updateAvailable: value }),
}))
