import * as React from "react"
import {
  Button,
  Tooltip as RootTooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui"
import { parsePhoneNumber } from "libphonenumber-js"
import { LockKeyhole, Pencil } from "lucide-react"
import { FlagImage } from "react-international-phone"
import { cn } from "./ui/utils"

interface AccountFieldProps {
  fieldDescription: React.ReactNode
  fieldValue: React.ReactNode
  buttonTooltip?: string
}

const getCountryCode = (phoneNumber: string): string | null => {
  try {
    const parsedNumber = parsePhoneNumber(phoneNumber)
    return parsedNumber.country ? parsedNumber.country : null
  } catch (error) {
    return null
  }
}

const Tooltip = ({
  content,
  children,
}: React.PropsWithChildren<{
  content: React.ReactNode
}>) => {
  return (
    <RootTooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent>{content}</TooltipContent>
    </RootTooltip>
  )
}

const FlagIcon = ({ code }) => {
  const _code = getCountryCode(code)

  return _code ? (
    <FlagImage
      iso2={_code.toLowerCase()}
      size="30px"
    />
  ) : null
}

const EditButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button> & { locked?: boolean }
>(({ locked = false, ...props }, ref) => {
  return (
    <Button
      ref={ref}
      {...props}
      variant="ghost"
      disabled={locked}
      className={cn("shrink-0", props.className)}
    >
      {locked ? (
        <>
          <LockKeyhole className="text-gray-600 w-4 h-4 mr-2" />
          Locked
        </>
      ) : (
        <span className="flex items-center">
          <Pencil
            fill="currentColor"
            className="text-gray-600 w-4 h-4 mr-2"
          />
          Edit
        </span>
      )}
    </Button>
  )
})
EditButton.displayName = "EditButton"

function AccountField({
  fieldDescription,
  fieldValue,
  buttonTooltip = "",
  children,
}: React.PropsWithChildren<AccountFieldProps>) {
  return (
    <div className="flex flex-col items-start justify-between mt-1">
      <p className="text-sm text-muted-foreground">{fieldDescription}</p>

      <div className="w-full flex-1 shrink-0 flex items-center justify-between">
        <p className="text-lg shrink-0 max-w-full">{fieldValue}</p>

        {buttonTooltip ? (
          <Tooltip content={buttonTooltip}>
            <span>{children}</span>
          </Tooltip>
        ) : (
          children
        )}
      </div>
    </div>
  )
}

AccountField.FlagIcon = FlagIcon
AccountField.EditButton = EditButton

export default AccountField
