import React, { useState } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { doc, updateDoc } from "firebase/firestore"
import { httpsCallable } from "firebase/functions"
import { ChevronLeft, ChevronRight } from "lucide-react"
import { useForm } from "react-hook-form"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useAnalytics } from "use-analytics"
import {
  Button,
  ConfirmationModal,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  RichEditor,
  toast,
} from "~/components/ui"
import { useAuth } from "~/context/AuthContext"
import {
  useGetNoteById,
  useNoteTransform,
  useRetryNoteTransform,
} from "~/hooks/useNotes"
import { db, functions } from "~/services/firebase"
import {
  formatTimestampAsNoteTitleDatePart,
  formatTimestampAsNoteTitleTimePart,
} from "~/utils/helperFunctions"
import { FeedbackButtons } from "./FeedbackButtons"
import { NoteStatus, TransformType } from "./types"

const formatText = (text: string) =>
  text.replace(/\*\*/g, "").replace(/\*/g, "-")

export default function NoteTransformPreview() {
  const location = useLocation()
  const navigate = useNavigate()
  const { noteId } = useParams()
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  const [isEditing, setIsEditing] = useState(false)
  const [feedback, setFeedback] = useState("")
  const [showDirtyNoteAlert, setShowDirtyNoteAlert] = useState(false)

  const transformType: TransformType =
    location.state?.transformType &&
    Object.values(TransformType).includes(location.state.transformType)
      ? location.state.transformType
      : TransformType.Default

  const noteQuery = useGetNoteById({ noteId: noteId! })
  const transformQuery = useNoteTransform({
    noteId: noteId!,
    transformType: transformType,
  })

  const note = noteQuery.data ?? null
  const transform = transformQuery.data ?? null
  const isLoading = noteQuery.isLoading || transformQuery.isLoading

  // Redirect to notes page if note does not exist
  React.useEffect(() => {
    if (noteQuery.error /* || transformQuery.error */) {
      navigate("/notes")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteQuery.error])

  // Handle transform not available
  const retryNoteTransform = useRetryNoteTransform({
    onSuccess: () => transformQuery?.refetch?.(),
  })

  React.useEffect(() => {
    if (!noteId) return

    if (transformQuery.error) {
      if (transformQuery.error?.message === "TRANSFORM_NOT_FOUND") {
        toast.error("Transform not available")
      } else {
        toast.error("Could not fetch the transform", {
          id: "note-transform-error",
          duration: Infinity,
          action: {
            label: "Retry",
            onClick: () =>
              retryNoteTransform.mutate({
                noteId: noteId!,
                transformType,
              }),
          },
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteId, transformType, transformQuery.error])

  React.useEffect(() => {
    if (transform && (transform?.status !== "ok" || !transform?.text)) {
      toast.error("Transform not available")
    }
  }, [transform])

  // Form
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const editorRef = React.useRef<React.ElementRef<typeof RichEditor> | null>(
    null
  )

  const transcription = transform?.text ? formatText(transform.text) : ""
  const form = useForm({
    values: {
      transcription,
    },
  })

  // MDXEditor is one-way binding, so we need to set the markdown content
  // when the transcription changes manually
  React.useEffect(() => {
    if (editorRef.current && transcription) {
      editorRef.current?.setMarkdown(transcription)
    }
  }, [transcription])

  const queryClient = useQueryClient()

  const updateTranscriptionDoc = async ({
    transcription,
  }: {
    transcription: string
  }): Promise<void> => {
    if (!transform) {
      throw new Error("Transform not available")
    }

    const updatedTranscription = transcription.trim()
    const noteRef = doc(db, `users/${currentUser?.uid}/notes/${noteId}`)

    await updateDoc(noteRef, {
      transformApplied: true,
      transformPromptId: transform?.promptId,
      editedTranscription: updatedTranscription,
      status: NoteStatus.Edited,
    })
  }

  const updateTranscription = useMutation({
    mutationFn: updateTranscriptionDoc,
    onSettled: () => {
      void track("Transform Applied", {
        promptId: transform?.promptId,
        version: transform?.version,
      })
    },
    onSuccess: () => {
      setIsEditing(false)
      void queryClient.invalidateQueries({ queryKey: ["NOTE"] })
      navigate(`/notes/${note!.id}`)
    },
    onError: () => {
      toast.error(
        "Could not update your note. Please try again or contact support@joy.day."
      )
    },
  })

  const logFeedback = async ({ feedback }: { feedback: string }) => {
    if (!transform) return

    // Get the function reference
    const addLogEvent = httpsCallable(functions, "addLogEvent")

    // Add the log event
    await addLogEvent({
      type: "Transform Feedback",
      properties: {
        noteId,
        feedback,
        promptId: transform?.promptId,
        status: transform?.status,
        version: transform?.version,
        elapsedTimeMs: transform?.metadata.elapsedTimeMs,
        model: transform?.metadata.model,
        requestTokens: transform?.metadata.requestTokens,
        responseTokens: transform?.metadata.responseTokens,
      },
    })

    // Add GA event
    void track("Transform Feedback", {
      feedback: feedback,
      promptId: transform?.promptId,
      version: transform?.version,
    })

    // TODO: Update the note with the feedback
  }

  const feedbackMutation = useMutation({
    mutationFn: logFeedback,
    onMutate: async ({ feedback }) => {
      setFeedback(feedback)

      if (feedback === "dislike") {
        toast.info("We'll do better next time, thank you. 🙏")
      }
    },
  })

  const isNoteDirty = Boolean(form.formState.dirtyFields?.transcription)
  const cancelClickHandler = async () => {
    if (isEditing && isNoteDirty) {
      setShowDirtyNoteAlert(true)
    } else {
      setIsEditing(false)
      navigateToTransformSelection()
    }
  }

  const navigateToTransformSelection = () => {
    void track("Transform Navigate_from_preview_to_selection", {
      promptId: transform?.promptId,
      version: transform?.version,
    })
    navigate(`/notes/${note?.id}/template-selection`)
  }

  const editClickHandler = () => {
    setIsEditing(true)
    setTimeout(() => form.setFocus("transcription"), 0)

    void track("Transform Edit_transcription", {
      promptId: transform?.promptId,
      version: transform?.version,
    })
  }

  return (
    <>
      <ConfirmationModal
        isOpen={showDirtyNoteAlert}
        onOpenChange={setShowDirtyNoteAlert}
        title="Leave without saving?"
        description="Your current changes will be lost."
        confirmButton="Confirm"
        closeButton="Cancel"
        onConfirm={navigateToTransformSelection}
      />

      <section className="relative bg-white w-full h-full px-6">
        <div className="flex flex-col h-full">
          <header className="flex justify-center items-center mt-1 mb-6 gap-1">
            <h2 className="font-platypi text-2xl pr-3">
              Happy with the result?
            </h2>

            <FeedbackButtons
              feedback={feedback}
              onFeedback={(feedbackType) => {
                feedbackMutation.mutate({ feedback: feedbackType })
              }}
            />
          </header>

          <div className="w-fit">
            <p className="pb-3">
              <span className="text-primary-black font-mono tracking-wide text-lg font-bold capitalize">
                {note?.createdAt
                  ? formatTimestampAsNoteTitleDatePart(note.createdAt)
                  : ""}
              </span>
              <span> · </span>
              <span className="text-gray-500 font-mono tracking-wide text-lg font-medium">
                {note?.createdAt
                  ? formatTimestampAsNoteTitleTimePart(note.createdAt)
                  : ""}
              </span>
            </p>
          </div>

          <div className="flex-1 overflow-auto">
            <Form
              ref={formRef}
              form={form}
              onSubmit={form.handleSubmit(async (values) => {
                await updateTranscription.mutateAsync(values)
                form.reset()
              })}
            >
              <FormField
                control={form.control}
                name="transcription"
                rules={{
                  required: "Transcription is required",
                }}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <RichEditor
                        {...field}
                        ref={(ref) => {
                          field.ref(ref)
                          editorRef.current = ref
                        }}
                        readOnly={!isEditing}
                        placeholder={"Your formatted note will appear here..."}
                        onRequestSave={() => {
                          if (form.formState.isDirty) {
                            formRef.current?.requestSubmit?.()
                          }
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </Form>
          </div>

          <div className="flex items-center justify-center w-full">
            <div className="flex flex-row absolute justify-center items-center gap-2 w-full note-edit-controls-bg pt-5 pb-10 mb-10">
              <Button
                variant="outline"
                className="border-0 h-12 p-2 rounded-lg-plus backdrop-blur-md-plus pr-4 bg-medium-grey-600"
                onClick={cancelClickHandler}
              >
                <span className="flex items-center text-[#131B2A] font-archivo text-lg font-medium leading-5-plus">
                  <ChevronLeft size={24} />
                  {isEditing ? "Cancel" : "Back"}
                </span>
              </Button>

              <Button
                variant="secondary"
                className={`h-12 p-2 px-4 rounded-lg-plus backdrop-blur-md-plus bg-[#121A29] ${isEditing ? "invisible" : ""}`}
                onClick={editClickHandler}
                disabled={isLoading}
              >
                <span className="text-white flex items-center font-archivo text-lg font-medium leading-5-plus">
                  Edit text
                </span>
              </Button>

              <Button
                variant="secondary"
                className="h-12 p-2 pl-4 rounded-lg-plus backdrop-blur-md-plus bg-[#121A29]"
                disabled={!transform && !isNoteDirty}
                isLoading={updateTranscription.isPending}
                onClick={(e) => {
                  e.preventDefault()
                  formRef.current?.requestSubmit?.()
                }}
              >
                <span className="text-white flex items-center font-archivo text-lg font-medium leading-5-plus">
                  Continue
                  <ChevronRight size={24} />
                </span>
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
