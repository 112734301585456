interface RecorderInspirationProps {
  languageCode: string
}

export default function RecorderInspiration({
  languageCode,
}: RecorderInspirationProps) {
  const INSPIRATION_TEXTS = [
    {
      key: "sv-inspiration",
      languageCode: "sv",
      title: ["Inga problem om du stakar dig eller tvekar, Joy fixar det."],
      subTitle: "Prova att läsa upp detta:",
      text: [
        "Anna berättar om stress på arbetet.",
        "Hon upplever ökande arbetsbörda.",
        "Detta har lett till ångest och sömnproblem.",
      ],
    },
    {
      key: "en-inspiration",
      languageCode: "en",
      title: ["Don't worry if you pause or stumble, Joy cleans it up."],
      subTitle: "Read this out loud",
      text: [
        "Jane speaks on work-related stress.",
        "She's facing a growing number of tasks.",
        "This surge in her workload is causing her anxiety and is affecting her sleep.",
      ],
    },
    {
      key: "nl-inspiration",
      languageCode: "nl",
      title: [
        "Maak je geen zorgen als je pauzeert of struikelt, Joy maakt het netjes.",
      ],
      subTitle: "Lees dit hardop voor",
      text: [
        "Jane spreekt over werkgerelateerde stress.",
        "Ze staat tegenover een groeiend aantal taken.",
        "Deze toename in haar werkdruk veroorzaakt angst en beïnvloedt haar slaap.",
      ],
    },
    {
      key: "pl-inspiration",
      languageCode: "pl",
      title: [
        "Nie martw się, jeśli zrobisz pauzę lub się zaczniesz.",
        "Joy to posprząta.",
      ],
      subTitle: "Przeczytaj to na głos",
      text: [
        "Anna opowiada o stresie związanym z pracą.",
        "Doświadcza wzrostu obciążenia pracą.",
        "Spowodowało to lęk i problemy ze snem.",
      ],
    },
  ]

  const inspiration = INSPIRATION_TEXTS.find(
    (text) => text.languageCode === languageCode
  )

  return (
    <div className="w-full md:w-[480px] min-h-[135px] bg-white rounded-lg flex flex-col items-center justify-center p-4 md:px-6 shadow-box-1">
      <h1 className="text-xl font-platypi text-balance">
        {inspiration?.title.map((text, index) => (
          <p
            key={"title" + index} // Fine to use index as key here since the list is static
            dangerouslySetInnerHTML={{ __html: text }}
            className="text-center"
          />
        ))}
      </h1>

      <h2 className="self-center text-xl font-platypi mt-2">
        {inspiration?.subTitle}
      </h2>

      <div className="text-md max-w-[300px] text-foreground space-y-1 my-4">
        {inspiration?.text.map((text, index) => (
          <p
            key={index} // Fine to use index as key here since the list is static
            dangerouslySetInnerHTML={{ __html: text }}
            className="text-center"
          />
        ))}
      </div>
    </div>
  )
}
