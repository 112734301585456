import { NoteExporter } from "~/components/NoteExporter"

export function PersonalDataCard() {
  return (
    <div className="bg-white shadow rounded-lg py-4 gap-y-3 gap-x-4">
      <h3 className="text-base leading-6 font-medium text-gray-900 px-4">
        Your Personal Data
      </h3>

      <hr className="border-t border-gray-200 mt-4"></hr>

      <div className="p-4 pb-0 flex items-center justify-between gap-4">
        <p className="text-sm">Export your notes</p>
        <NoteExporter />
      </div>
    </div>
  )
}
