import React from "react"
import { useQueryClient } from "@tanstack/react-query"
import { Crisp } from "crisp-sdk-web"
import { useAnalytics } from "use-analytics"
import { Button, toast } from "~/components/ui"
import {
  Credenza,
  CredenzaBody,
  CredenzaContent,
  CredenzaFooter,
  CredenzaHeader,
  CredenzaTitle,
} from "~/components/ui/credenza"
import { useDeleteNote } from "~/hooks/useNotes"
import { downloadRecording } from "~/utils/downloadRecordings"
import { Note } from "./types"

interface NoteErrorDialogProps {
  noteWithError: Note | undefined
  setNoteWithError: (note: Note | undefined) => void
}

const NoteErrorDialog: React.FC<NoteErrorDialogProps> = ({
  noteWithError,
  setNoteWithError,
}) => {
  const queryClient = useQueryClient()
  const { track } = useAnalytics()
  const [isDownloading, setIsDownloading] = React.useState(false)

  const invalidateNote = React.useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: ["NOTE", noteWithError?.id],
      }),
    [queryClient, noteWithError]
  )

  const deleteNote = useDeleteNote({
    onSettled: async (_, error) => {
      if (error) {
        toast.error("Error deleting note, please try again!")
      } else {
        void track("Note Deleted_from_error_dialog")
        await invalidateNote()
        setNoteWithError(undefined)
      }
    },
  })

  const handleDownloadRecording = async () => {
    setIsDownloading(true)
    void track("Note Recording_downloaded_from_error_dialog")
    try {
      if (noteWithError !== undefined) {
        await downloadRecording(noteWithError)
      }
    } catch (error) {
      console.log(error)
      toast.error("Could not download the recording, contact support")
      void track("Note Error_downloading_recording_from_error_dialog")
    } finally {
      setIsDownloading(false)
    }
  }

  const handleTalkToSupport = () => {
    void track("Note Support_initiated_from_error_dialog")
    if (!Crisp.chat.isChatOpened()) {
      Crisp.chat.open()
    }
    Crisp.message.sendText(
      "Hello, I have an issue with note: " + noteWithError?.id
    )
  }

  return (
    <Credenza
      open={noteWithError !== undefined}
      onOpenChange={() => {
        setNoteWithError(undefined)
      }}
    >
      <CredenzaContent className={""}>
        <CredenzaHeader className="space-y-0.5">
          <CredenzaTitle>Sorry...</CredenzaTitle>
        </CredenzaHeader>
        <CredenzaBody
          data-vaul-no-drag=""
          className="overflow-y-auto max-h-96 md:max-h-[27rem] px-4"
        >
          <div className="flex flex-col justify-center gap-1 md:gap-2">
            <p>... something went wrong when transcribing your note.</p>
            <h4 className="self-center md:self-start  font-bold mb-4">
              You can now
            </h4>

            <div className="self-center md:self-start flex flex-row md:flex-col gap-2">
              <Button
                onClick={handleTalkToSupport}
                size="sm"
                variant="secondary"
              >
                Talk to support
              </Button>
              <Button
                onClick={() =>
                  deleteNote.mutate({ noteId: noteWithError?.id ?? "" })
                }
                size="sm"
                variant="secondary"
              >
                Delete this note
              </Button>
              <Button
                onClick={(event) => {
                  event.preventDefault()
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  handleDownloadRecording()
                }}
                size="default"
                variant="secondary"
              >
                {isDownloading ? "Downloading..." : "Download the recording"}
              </Button>
            </div>
          </div>
        </CredenzaBody>

        <CredenzaFooter className="">
          <Button
            variant={"default"}
            onClick={() => {
              setNoteWithError(undefined)
            }}
          >
            I&apos;ll do it later
          </Button>
        </CredenzaFooter>
      </CredenzaContent>
    </Credenza>
  )
}

export default NoteErrorDialog
