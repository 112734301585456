import React, { useEffect } from "react"
import * as Sentry from "@sentry/browser"
import { Crisp } from "crisp-sdk-web"

const isCrispEnabled = () => Boolean(import.meta.env.VITE_APP_CRISP_ENABLED)

const CrispChat: React.FC = () => {
  // Initialize Crisp chat
  // Reset Crisp session on unmount
  useEffect(() => {
    if (!isCrispEnabled()) {
      console.log("Crisp is not enabled")
      return
    }

    Crisp.configure(import.meta.env.VITE_APP_CRISP_ID)
    return () => {
      try {
        Crisp.setTokenId("")
        Crisp.session?.reset()
        delete window.$crisp
      } catch (error) {
        // Ignore error that occurs when Crisp is not initialized
        if (
          error instanceof TypeError &&
          error.message === "window.$crisp.do is not a function"
        ) {
          // Do nothing
        } else {
          Sentry.captureException(error)
        }
      }
    }
  }, [])

  return null
}

export default CrispChat
