import * as React from "react"
import { CheckIcon, ClipboardIcon } from "lucide-react"
import { Button, ButtonProps } from "./button"
import { cn } from "./utils"

interface CopyButtonProps extends ButtonProps {
  value: string
}

export function copyToClipboardWithMeta(value: string) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  ;(window as any).navigator?.clipboard?.writeText?.(value)
}

export function CopyButton({
  value,
  className,
  variant = "ghost",
  ...props
}: CopyButtonProps) {
  const [hasCopied, setHasCopied] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setHasCopied(false)
    }, 2000)
  }, [hasCopied])

  return (
    <Button
      size="icon"
      variant={variant}
      className={cn(
        "shrink-0 relative z-10 p-0 h-6 w-6 [&_svg]:h-3.5 [&_svg]:w-3.5 [&_svg]:stroke-black",
        className
      )}
      onClick={() => {
        copyToClipboardWithMeta(value)
        setHasCopied(true)
      }}
      {...props}
    >
      <span className="sr-only">Copy</span>
      {hasCopied ? <CheckIcon /> : <ClipboardIcon />}
    </Button>
  )
}
