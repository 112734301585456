import * as React from "react"
import { useMutation } from "@tanstack/react-query"
import { saveAs } from "file-saver"
import { collection, doc, getDocs } from "firebase/firestore"
import JSZip from "jszip"
import { DownloadIcon } from "lucide-react"
import { firstValueFrom } from "rxjs"
import sanitizeFilename from "sanitize-filename"
import { Button, toast } from "~/components/ui"
import { useAuth } from "~/context/AuthContext"
import type { Client } from "~/hooks/firestore/useClients"
import { getNotes$ } from "~/hooks/useNotes"
import type { Note } from "~/pages/Notes/types"
import { db } from "~/services/firebase"
import { getTranscribedNote, isValidNoteForDisplay } from "~/utils/noteUtils"

type NoteExport = Note & {
  client?: Client
  transcribed: string
}

function convertNoteToFrontMatter(note: NoteExport): string {
  return `---
id: ${note.id || ""}
title: ${note.title || ""}
clientId: ${note.clientId || ""}
clientName: ${note?.client?.name || ""}
createdAt: ${note.createdAt?.toDate()?.toISOString?.() || ""}
status: ${note.status || ""}
---

${note.transcribed || ""}
`
}

async function fetchClients(userId: string): Promise<Record<string, Client>> {
  const snapshot = await getDocs(collection(db, `users/${userId}/clients`))

  return snapshot.docs.reduce(
    (acc, doc) => {
      const { name, properties, associatedNoteIds } = doc.data()
      acc[doc.id] = { id: doc.id, name, properties, associatedNoteIds }
      return acc
    },
    {} as Record<string, Client>
  )
}

async function fetchAllNotes(userId: string) {
  const allNotes: Note[] = []

  let cursor: { after: number } | null = null
  let hasNext = true

  while (hasNext) {
    const result = await firstValueFrom(
      getNotes$(userId, cursor ? { after: cursor.after } : { after: 0 })
    )

    for (const note of result.data) {
      const noteRef = doc(db, `users/${userId}/notes/${note.id}`)
      note.transcribed = await getTranscribedNote(note, noteRef, userId)
        // silently ignore errors
        .catch(() => void 0)

      allNotes.push(note)
    }

    if (result.nextCursor) {
      cursor = { after: result.nextCursor }
    } else {
      hasNext = false
    }
  }

  return allNotes.filter((n) => isValidNoteForDisplay(n)) as NoteExport[]
}

export function useExportNotes() {
  const buildNumber = (date: Date) => {
    return `${date.getFullYear().toString().slice(2)}${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}${date.getDate().toString().padStart(2, "0")}${date
      .getHours()
      .toString()
      .padStart(2, "0")}${date.getMinutes().toString().padStart(2, "0")}`
  }

  return useMutation({
    mutationFn: async (userId: string) => {
      const [clients, notes] = await Promise.all([
        fetchClients(userId),
        fetchAllNotes(userId),
      ])

      const zip = new JSZip()

      // Add each note as a .md file
      notes.forEach((note) => {
        // Attach client data to note
        note.client = note.clientId ? clients[note.clientId] : undefined

        const fileName = sanitizeFilename(
          [
            `${note?.client?.name ?? ""}`,
            buildNumber(note?.createdAt?.toDate()),
            note.id,
          ]
            .filter(Boolean)
            .join("_")
        )

        const content = convertNoteToFrontMatter(note)
        zip.file(`${fileName}.md`, content)
      })

      // Generate the zip file, then trigger download
      const zipBlob = await zip.generateAsync({ type: "blob" })
      saveAs(zipBlob, `notes-${buildNumber(new Date())}.zip`)
    },
    onMutate: () => {
      toast.info("Preparing notes for export...", {
        id: "exporting-notes",
        duration: Infinity,
      })
    },
    onSuccess: () => {
      toast.success("Notes exported successfully!", {
        id: "exporting-notes",
        duration: 5000,
      })
    },
    onError: (error) => {
      console.error("Error exporting notes:", error)
      toast.error("Error exporting notes, please try again!", {
        id: "exporting-notes",
        duration: 5000,
      })
    },
  })
}

export function NoteExporter() {
  const { currentUser } = useAuth()

  const exportNotesMutation = useExportNotes()

  const handleExport = () => {
    exportNotesMutation.mutate(currentUser!.uid, {})
  }

  return (
    <Button
      size="sm"
      disabled={exportNotesMutation.isPending}
      onClick={() => handleExport?.()}
    >
      <DownloadIcon className="size-4" />
      <span>{exportNotesMutation.isPending ? "Exporting..." : "Export"}</span>
    </Button>
  )
}
