import { useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { Button, toast } from "~/components/ui"
import { useStore } from "~/hooks/useStore"

const checkForNewVersion = async (): Promise<string> => {
  const baseUrl = window.location.origin

  return await fetch(
    `${baseUrl}/manifest.webmanifest${import.meta.env.PROD ? `?t=${Date.now()}` : ""}`,
    { cache: "no-cache" }
  )
    .then((response) => response.json())
    .then((data) => data?.build || "1.0dev")
}

export function UpdateNotifier() {
  const [currentVersion, setCurrentVersion] = useState<string | null>(null)

  const { updateAvailable, setUpdateAvailable } = useStore((state) => ({
    updateAvailable: state.updateAvailable,
    setUpdateAvailable: state.setUpdateAvailable,
  }))

  const { data: version } = useQuery({
    queryKey: ["versionCheck"],
    queryFn: checkForNewVersion,
    refetchInterval: 30_000, // Refetch every 30 seconds
    refetchIntervalInBackground: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    // Set the current version if it's not set yet,
    if (!currentVersion && version) {
      setCurrentVersion(version)
      console.info("> Current version:", version)
    }

    // Notify the user if a new version is available
    if (currentVersion && version && currentVersion !== version) {
      console.info("> New version available:", version)
      setUpdateAvailable(true)
    }
  }, [currentVersion, version])

  useEffect(() => {
    if (updateAvailable) {
      toast("New version available", {
        id: "new-version",
        description: "Please update the app to get the latest features",
        position: "bottom-left",
        dismissible: false,
        action: (
          <Button
            size="sm"
            className="px-2 py-1 h-auto text-xs font-normal rounded"
            variant="secondary"
            onClick={() => {
              window.location.reload()
            }}
          >
            Update
          </Button>
        ),
        cancel: (
          <Button
            size="sm"
            className="px-2 py-1 h-auto text-xs font-normal rounded"
            variant="outline"
            onClick={() => {
              toast.dismiss("new-version")
            }}
          >
            Later
          </Button>
        ),
        duration: Infinity,
      })
    }
  }, [updateAvailable])

  return null
}
